export function initRedirectedCreateAccount() {
    const buttons = document.querySelectorAll('[data-redirected-create-account]');
    const urlCreateAccount = document.querySelector('[data-redirected-url]').dataset.redirectedUrl;
    const utm = window.location.search;

    buttons.forEach((button) => {
        button.addEventListener('click', (button) => {
            sendData();
        });
    });

    async function sendData() {
        const url = document.querySelector('[data-url-create-account]').dataset.urlCreateAccount;
        const token = document.querySelector('[data-trial-reverse-token]').value;
        const formData = new FormData();
        formData.append('token', token);
        formData.append('phoneNumber', '');

        try {
            const response = await fetch(url, {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                throw new Error(`Error: ${response.status} ${response.statusText}`);
            }

            const responseData = await response;

            if (responseData.ok) {
                window.location.href = `${urlCreateAccount}${utm}`;
            }
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
}
